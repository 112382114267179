import React from 'react';

const ReturnsPolicy = () => {
  return (
    <div>
      <h1>Returns Policy</h1>
      {/* Add your returns policy content here */}
    </div>
  );
};

export default ReturnsPolicy;
